import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root'
})
export class MemberService {

  constructor(
    private afAuth: AngularFireAuth,
    private afData: AngularFireDatabase) { }

  init() {
    var userId = this.afAuth.auth.currentUser.uid;
    var email = this.afAuth.auth.currentUser.email;
    var inventoryId = this.afData.createPushId();
    return new Promise((res, rej) => {
      this.afData.object(`members/${userId}`).set({ 'inventory': inventoryId, 'email': email })
        .then(() => {
          return this.afData.object(`inventories/${inventoryId}/user`).set(userId);
        })
        .then(() => {
          return this.get();
        });
    })
  }

  get() {
    var userId = this.afAuth.auth.currentUser.uid;
    return this.afData.object(`members/${userId}`);
  }
}
