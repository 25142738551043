import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { MemberService } from './member.service';

@Injectable()
export class MemberResolver implements Resolve<any> {
    constructor(private memberService: MemberService) { }

    resolve() {
        return new Promise((resolve, reject) => {
            this.memberService.get().valueChanges().subscribe(val => {
                resolve(val);
                // // if new member, initialize
                // if (val == null) {
                //     this.memberService.init().then(val => {
                //         resolve(val);
                //     });
                // } else {
                //     resolve(val);
                // }
            });
        })
    }

}
