export const environment = {
  production: false,
  firebase : {
    apiKey: "AIzaSyAZ_CetlD2z7tZgPadXPOA9PAolE6mCBYI",
    authDomain: "yinventory-70201.firebaseapp.com",
    databaseURL: "https://yinventory-70201.firebaseio.com",
    projectId: "yinventory-70201",
    storageBucket: "yinventory-70201.appspot.com",
    messagingSenderId: "225784870075"
  }
};
